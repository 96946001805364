.navbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    user-select: none;

    div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 10px;

        h3 {
            margin-bottom: 8px;
        }

        label {
            margin-bottom: 5px;
        }

        input[type='checkbox'],
        input[type='radio'] {
            margin-right: 5px;
        }
    }

    .navigation {

        flex-direction: row;
        gap: 25px;
        padding: 0 15px;

        .additional-options {
            flex-direction: column;
        }

        .navigation-item {
            position: relative;

            h3 {
                cursor: pointer;
            }

            .dropdown-panel {
                position: absolute;
                top: 45px;
                background: #f0f0f0;
                width: 200px;
                z-index: 9;
                box-shadow: 5px 7px 8px #ccc;
            }
        }

        .dropdown-panel {
            display: flex;
            flex-direction: column;
            background-color: #f0f0f0;
            padding: 10px;
            margin-top: 5px;
            user-select: none;

            label {
                margin-bottom: 5px;
                display: flex;
                align-items: flex-start;
            }
        }
    }

    .additional-options {
        align-items: flex-end;

        .zoom-controls,
        .legend-list {
            flex-direction: row;
        }

        .zoom-controls {
            button {
                padding: 5px;
                border: none;
                cursor: pointer;
            }
        }

        .legend-list {

            display: flex;
            display: -webkit-flex;
            justify-content: right;
            font-size: 10px;
            margin-top: 0px;
            font-family: AvenirNext-Medium;

            .pr-color-high {
                background: rgb(52, 109, 55);
            }

            .pr-color-middle {
                background-color: rgb(233, 140, 50);
            }

            .pr-color-lower {
                background: rgb(160, 29, 42);
            }

            .pr-legend-list {
                display: flex;
                display: -webkit-flex;
                justify-content: right;
                font-size: 10px;
                margin-top: 0px;
                font-family: AvenirNext-Medium;
            }

            .pr-font-Medium {
                font-family: AvenirNext-Medium;
                color: #53514f;
            }

            .pr-legend {
                width: 20px;
                height: 10px;
                display: inline-block;
                border-radius: 3px;
                margin-right: 5px;
                margin-left: 5px;
                vertical-align: -2px;
            }

        }
    }
}