@charset "UTF-8";

@font-face {
    font-family: "AvenirNext-Medium";
    src: url("./assets/fonts/AvenirNext-Medium/AvenirNext-Medium.woff2") format("woff2"), url("./assets/fonts/AvenirNext-Medium/AvenirNext-Medium.woff") format("woff"), url("./assets/fonts/AvenirNext-Medium/AvenirNext-Medium.ttf") format("truetype"), url("./assets/fonts/AvenirNext-Medium/AvenirNext-Medium.eot") format("embedded-opentype"), url("./assets/fonts/AvenirNext-Medium/AvenirNext-Medium.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "AvenirNext-Regular";
    src: url("./assets/fonts/AvenirNext-Regular/AvenirNext-Regular.woff2") format("woff2"), url("./assets/fonts/AvenirNext-Regular/AvenirNext-Regular.woff") format("woff"), url("./assets/fonts/AvenirNext-Regular/AvenirNext-Regular.ttf") format("truetype"), url("./assets/fonts/AvenirNext-Regular/AvenirNext-Regular.eot") format("embedded-opentype"), url("./assets/fonts/AvenirNext-Regular/AvenirNext-Regular.svg") format("svg");
    font-weight: normal;
    font-style: normal;
    outline: none;
}

@font-face {
    font-family: "AvenirNext-DemiBold";
    src: url("./assets/fonts/AvenirNext-DemiBold/AvenirNext-DemiBold.woff2") format("woff2"), url("./assets/fonts/AvenirNext-DemiBold/AvenirNext-DemiBold.woff") format("woff"), url("./assets/fonts/AvenirNext-DemiBold/AvenirNext-DemiBold.ttf") format("truetype"), url("./assets/fonts/AvenirNext-DemiBold/AvenirNext-DemiBold.eot") format("embedded-opentype"), url("./assets/fonts/AvenirNext-DemiBold/AvenirNext-DemiBold.svg") format("svg");
}

body {
    background-color: #ccc;
    font-family: AvenirNext-Regular;
}

.App {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    max-width: 650px;
    margin: 0 auto;
    background-color: #fff;
}