.data-wrapper {
    width: 100%;
    height: 650px;
    overflow-x: auto;
    overflow-y: auto;
    position: relative;
}

.table-container {
    max-width: 100%;
    overflow: scroll;

    table {
        transform-origin: 0px 0px;
    }
}

th {
    height: 220px;
    line-height: 14px;
    padding-bottom: 20px;
    text-align: left;
    vertical-align: bottom;
}

div.rotate {
    -ms-writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    white-space: nowrap;
    font-size: 10pt;
    position: relative;
    left: 0.2vw;
}

.table-container {
    margin-top: 20px;
    transform-origin: 0 0;

    table {
        border-spacing: 5px;

        th,
        td {
            padding: 0 8px;
            text-align: center;
            font-size: 10pt;
            color: #fff;
            font-size: 6pt;
        }

        th {
            text-align: left;
            color: black;

            &.cumulative {
                color: rgb(63, 115, 160);
                font-weight: bold;
                font-family: AvenirNext-DemiBold;
            }

            &.totals {
                font-weight: bold;
                font-family: AvenirNext-DemiBold;
            }
        }

        td {
            font-family: AvenirNext-Medium;
            font-size: 6pt;
            text-align: center;
            height: 13px;
            vertical-align: middle;
            line-height: 15px;
            border-radius: 5px;
            color: #fff;


            &.red {
                background-color: #ffcccc;
            }

            &.orange {
                background-color: #ffe0b2;
            }

            &.green {
                background-color: #ccffcc;
            }

            &.maturity-level {
                color: black;
            }
        }
    }

    table {
        td {
            &.identity {
                text-align: right;
                color: black;
                font-size: 10pt;
            }
        }
    }

    .cumulativeManager,
    .cumulativeEmployee {
        .identity {
            color: rgb(63, 115, 160);
            font-weight: bold;
            font-family: AvenirNext-DemiBold;
        }
    }

    .cumulativeTotal {
        .identity {
            font-weight: bold;
            font-family: AvenirNext-DemiBold;
            white-space: nowrap;
        }
    }

}